export const WelcomeText = [
  {
    text: "Welcome"
  },
  {
    text: "Bienvenue"
  },
  {
    text: "欢迎"
  },
  {
    text: "Bienvenido"
  },
  {
    text: "Benvenuto"
  },
  {
    text: "أهلا بك"
  },
  {
    text: "Bem-vindo"
  },
  {
    text: "Välkommen"
  },
  {
    text: "ようこそ"
  },
  {
    text: "Hoş geldin"
  },
  {
    text: "Bine ati venit"
  },
  {
    text: "ברוך הבא"
  },
  {
    text: "Witaj"
  },
  {
    text: "어서 오십시오"
  },
  {
    text: "Selamat datang"
  },
  {
    text: "Добро пожаловать"
  },
  {
    text: "ਸਵਾਗਤ ਹੈ"
  },
  {
    text: "Kaabo"
  },
  {
    text: "Welkom"
  },
  {
    text: "Chào mừng"
  },
  {
    text: "Maligayang pagdating"
  },
  {
    text: "καλως ΗΡΘΑΤΕ"
  },
]